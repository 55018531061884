<template>
  <div>
    <b-row>
      <b-col cols="12">
        <form-detail
          v-if="landingPageShow === true"
          @refetch-empty-data="refetchEmptyData"
        />
        <empty-page
          v-else
          :empty-kind-page="'landing-page'"
          :header-string="'No Landing Page found'"
          :content-string="'It seems, you have no Landing Page yet. Let’s create one, so you can start marketing campaign and receive potential customer submission form. '"
          :link-to-name="'landing-pages-create'"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import EmptyPage from '@/views/menus/EmptyPage.vue'
import FormDetail from './FormDetail.vue'

export default {
  components: {
    BRow,
    BCol,

    FormDetail,
    EmptyPage,
  },
  data() {
    return {
      landingPageShow: true,
    }
  },
  methods: {
    refetchEmptyData() {
      this.landingPageShow = false
    },
  },
}
</script>
