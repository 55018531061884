export default
[
  { html: '<span class="text-primary">Purple</span>', value: 'primary' },
  { html: '<span class="text-danger">Red</span>', value: 'danger' },
  { html: '<span class="text-info">Light Blue</span>', value: 'info' },
  { html: '<span class="text-secondary">Gray</span>', value: 'secondary' },
  { html: '<span class="text-success">Green</span>', value: 'success' },
  { html: '<span class="text-warning">Orange</span>', value: 'warning' },
  { html: '<span class="text-dark">Black</span>', value: 'dark' },
]
