<template>
  <b-card :title="(this.$route.params.id !== undefined) ? 'Edit Landing Page' : 'Create Landing Page'">
    <b-overlay
      :show="this.$route.params.id !== undefined && loadingState === true"
      variant="light"
      :opacity="0.7"
      blur="2px"
      rounded="sm"
    >
      <b-row class="landing-page-content flex-column-reverse flex-md-row">
        <b-col cols="12">
          <b-tabs>
            <!-- Landing Page -->
            <b-tab
              active
              title="Landing Page"
            >
              <div>
                <b-form
                  ref="form"
                  class="repeater-form mb-2"
                  @submit.prevent="submitForm"
                >
                  <b-row class="d-flex justify-content-start main-form">
                    <!-- Form -->
                    <b-col
                      cols="12"
                      md="6"
                      class="home-tab-content"
                    >
                      <b-row class="">
                        <!-- Title -->
                        <b-col md="12">
                          <b-form-group>
                            <label for="label">Label (length &lt; 50, will be used for URL)</label>
                            <small class="text-danger font-weight-bold"> *</small>
                            <b-form-input
                              id="label"
                              ref="label"
                              v-model="formData.label"
                              :maxlength="50"
                              type="text"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_label"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- SLug -->
                        <b-col
                          md="12"
                        >
                          <label
                            size="md"
                            for="slug"
                          >{{ selfData === null ? 'slug /' : 'tracebit.net/' + selfData.companyName + '/' + slug }}</label>
                        </b-col>
                        <!-- Duration & Target -->
                        <b-col cols="12">
                          <h4 class="mt-2">
                            Duration & Target
                          </h4>
                        </b-col>
                        <!-- Expired Period -->
                        <b-col
                          md="6"
                          class=""
                        >
                          <b-form-group>
                            <label for="expired_start_date">Start Period</label>
                            <small class="text-danger font-weight-bold"> *</small>
                            <flat-pickr
                              id="expired_start_date"
                              ref="expired_start_date"
                              v-model="formData.expired_start_date"
                              class="form-control mb-1"
                              :config="{ dateFormat: 'd-m-Y'}"
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_expired_start_date"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Finish Period -->
                        <b-col
                          md="6"
                        >
                          <b-form-group>
                            <label for="expired_finish_date">Finish Period</label>
                            <small class="text-danger font-weight-bold"> *</small>
                            <flat-pickr
                              id="expired_finish_date"
                              ref="expired_finish_date"
                              v-model="formData.expired_finish_date"
                              class="form-control mb-1"
                              :config="{ dateFormat: 'd-m-Y' }"
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_expired_finish_date"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Total Sales Target -->
                        <b-col md="12">
                          <b-form-group>
                            <label for="sales_goal_deal">Total Sales Target</label>
                            <b-input-group>
                              <b-input-group-prepend is-text>
                                Rp
                              </b-input-group-prepend>
                              <cleave
                                id="sales_goal_deal"
                                ref="sales_goal_deal"
                                v-model="formData.sales_goal_deal"
                                class="form-control"
                                :raw="false"
                                :options="currencyFormat"
                              />
                            </b-input-group>
                            <div class="py-0">
                              <span
                                ref="invalid_sales_goal_deal"
                                class="text-danger font-small-3"
                              />
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- Total Leads Target -->
                        <b-col
                          md="12"
                        >
                          <b-form-group>
                            <label for="submission_goal">Total Leads Target</label>
                            <small class="text-danger font-weight-bold"> *</small>
                            <b-form-input
                              id="submission_goal"
                              ref="submission_goal"
                              v-model="formData.submission_goal"
                              type="number"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_submission_goal"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Header Section -->
                        <b-col cols="12">
                          <h4 class="mt-2">
                            Header Section
                          </h4>
                        </b-col>
                        <!-- Heading Title -->
                        <b-col
                          md="12"
                        >
                          <b-form-group>
                            <label for="title">Heading Title (max 30 char)</label>
                            <small class="text-danger font-weight-bold"> *</small>
                            <b-form-input
                              id="title"
                              ref="title"
                              v-model="formData.title"
                              :maxlength="30"
                              type="text"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_title"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Heading Paragraph -->
                        <b-col md="12">
                          <b-form-group>
                            <label for="description">Heading Paragraph (max 120 char)</label>
                            <small class="text-danger font-weight-bold"> *</small>
                            <b-form-textarea
                              id="description"
                              ref="description"
                              v-model="formData.description"
                              :maxlength="120"
                              trim
                              placeholder=""
                              rows="1"
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_description"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Logo -->
                        <b-col
                          md="12"
                        >
                          <b-form-group
                            label="Logo (.jpeg, .jpg, .png, .svg, .heic, max size 300KB)"
                            label-for="icon"
                          >
                            <b-form-file
                              id="icon"
                              ref="icon"
                              v-model="formData.icon"
                              :placeholder="formData.icon != null ? formData.icon : 'Choose a file or drop it here...'"
                              drop-placeholder="Drop file here..."
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_icon"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Hero Background -->
                        <b-col
                          md="12"
                        >
                          <b-form-group
                            label="Hero Background (.jpeg, .jpg, .png, .heic, max size 3MB)"
                            label-for="hero_image"
                          >
                            <b-form-file
                              id="hero_image"
                              ref="hero_image"
                              v-model="formData.hero_image"
                              :placeholder="formData.hero_image != null ? formData.hero_image : 'Choose a file or drop it here...'"
                              drop-placeholder="Drop file here..."
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_hero_image"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- basic modal -->
                        <b-modal
                          id="modal-preview"
                          centered
                          title="Form Preview"
                          hide-footer
                        >
                          <form-field
                            :hidden-submit-button="true"
                            :form-id="parseInt(formData.form_id)"
                          />
                        </b-modal>
                        <!-- Form -->
                        <b-col cols="12">
                          <h4 class="mt-2">
                            Form
                          </h4>
                        </b-col>
                        <!-- Choose Form -->
                        <b-col
                          md="12"
                        >
                          <b-form-group>
                            <label for="form_id">Choose Form</label>
                            <small class="text-danger font-weight-bold"> *</small>
                            <b-row class="w-100 m-0">
                              <b-col class="pl-0">
                                <div>
                                  <v-select
                                    id="form_id"
                                    ref="form_id"
                                    v-model="formData.form_id"
                                    class="w-100"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    label="label"
                                    :clearable="false"
                                    :reduce="label => label.value"
                                    :options="formOptions"
                                    :disabled="formData.submissions_count > 0 || this.$route.params.id !== undefined"
                                  />
                                </div>
                              </b-col>
                              <b-col
                                cols="2"
                                lg="1"
                                class="pr-0"
                              >
                                <div class="d-flex justify-content-end">
                                  <b-button
                                    v-show="formData.submissions_count <= 0"
                                    v-b-tooltip.hover.v-secondary
                                    title="Refresh"
                                    variant="outline-primary"
                                    @click="getAllForms"
                                  >
                                    <feather-icon icon="RefreshCwIcon" />
                                  </b-button>
                                </div>
                              </b-col>
                            </b-row>
                            <span
                              v-if="formData.form_id != null"
                              class="mt-1 font-small-2 mr-2"
                            >
                              <b-button
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                                v-b-modal.modal-preview
                                v-b-tooltip.hover.top="'Preview'"
                                variant="flat-primary"
                                class="mx-0 my-0 px-0 py-0"
                              >
                                <span class="font-small-2">Click here to see the form</span>
                              </b-button>
                            </span>
                            <span
                              v-show="formData.submissions_count <= 0"
                              class="mt-3 font-small-2"
                            >
                              <b-link
                                :to="{ name: 'form-managers-create' }"
                                variant="flat-primary"
                                target="_blank"
                                class="mx-0 px-0"
                              >
                                <span class="font-small-2">Create new form</span>
                              </b-link>
                            </span>
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_form_id"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Fill in sentence -->
                        <b-col
                          md="12"
                          class="mb-1"
                        >
                          <b-form-group>
                            <label for="fill_in_sentence">Fill in sentence</label>
                            <b-form-input
                              id="fill_in_sentence"
                              ref="fill_in_sentence"
                              v-model="formData.fill_in_sentence"
                              class="w-100"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- Color Submit button -->
                        <b-col cols="12">
                          <span class="font-small-3">Submit Button</span>
                        </b-col>
                        <b-col
                          lg="6"
                          xs="12"
                        >
                          <b-form-group>
                            <div
                              class="d-flex mt-1"
                              style="max-height: 40px; align-items: center;"
                            >
                              <div class="w-100">
                                <b-button
                                  class="submit_btn_preview"
                                  block
                                  :style="{
                                    background: color + ' !important',
                                    border: 0,
                                    height: '40px',
                                    padding: 0,
                                  }"
                                  @click="showColorPicker = !showColorPicker"
                                >
                                  <span class="align-middle">{{ formData.label_submit_button === '' ? 'Submit' :formData.label_submit_button }}</span>
                                </b-button>
                              </div>
                              <div class="text-center w-100">
                                <img
                                  :src="require('@/assets/images/icons/color-picker.png')"
                                  role="button"
                                  style="height: 30px;"
                                  alt="Help"
                                  @click="showColorPicker = !showColorPicker"
                                >
                              </div>
                            </div>
                          </b-form-group>
                        </b-col>
                        <b-col
                          lg="6"
                          xs="12"
                          class="color-picker d-flex"
                          style="max-height: 70px; align-items: end;"
                        >
                          <transition name="slide-fade">
                            <b-card
                              v-if="showColorPicker"
                              class="color-picker-card"
                              style="box-shadow: 0 0 16px 0 rgb(0 0 0 / 16%) !important; z-index: 1"
                              @click.stop
                            >
                              <button
                                type="button"
                                class="color-picker-close close"
                                data-dismiss="modal"
                                @click="showColorPicker = !showColorPicker"
                              >
                                &times;
                              </button>
                              <color-picker
                                theme="light"
                                :color="color"
                                :sucker-hide="true"
                                @changeColor="changeColor"
                              />
                            </b-card>
                          </transition>
                        </b-col>
                        <!-- Label Submit button -->
                        <b-col
                          md="12"
                        >
                          <b-form-group
                            label-for="label_submit_button"
                          >
                            <span class="font-small-3">Call-to-Action words (max 20 char)<span class="text-danger">*</span></span>
                            <b-form-input
                              id="label_submit_button"
                              ref="label_submit_button"
                              v-model="formData.label_submit_button"
                              :maxlength="20"
                              type="text"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_label_submit_button"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Body -->
                        <b-col cols="12">
                          <h4 class="mt-2">
                            Body
                          </h4>
                        </b-col>
                        <!-- Paragraph -->
                        <b-col
                          md="12"
                        >
                          <b-form-group>
                            <label for="body_description">Paragraph</label>
                            <small class="text-danger font-weight-bold"> *</small>
                            <quill-editor
                              id="body_description"
                              ref="body_description"
                              v-model="formData.body_description"
                              :options="editorOption"
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_body_description"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Term & Conditions -->
                        <b-col
                          md="12"
                        >
                          <b-form-group>
                            <label for="aggreement">Term & Conditions</label>
                            <small class="text-danger font-weight-bold"> *</small>
                            <quill-editor
                              id="aggreement"
                              ref="aggreement"
                              v-model="formData.aggreement"
                              :options="editorOption"
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_aggreement"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </b-col>
                        <!-- Links -->
                        <b-col
                          md="12"
                        >
                          <span class="demo-inline-spacing">
                            <h5>Add new link</h5>
                            <b-button
                              size="sm"
                              variant="gradient-primary"
                              class="btn-icon rounded-circle btn-form-repeater"
                              @click="repeateAgain"
                            >
                              <feather-icon icon="PlusIcon" />
                            </b-button>
                          </span>

                          <!-- Row Loop -->
                          <b-row
                            v-for="(item, index) in items"
                            :id="item.id"
                            :key="item.id"
                            ref="row"
                          >
                            <!-- Label -->
                            <b-col cols="5">
                              <b-form-group
                                label="Label"
                                label-for="text_link"
                              >
                                <b-form-input
                                  id="text_link"
                                  ref="text_link"
                                  v-model="formData.text_link[index]"
                                  type="text"
                                />
                              </b-form-group>
                            </b-col>

                            <!-- Link -->
                            <b-col cols="5">
                              <b-form-group
                                label="Complete URL"
                                label-for="url_link"
                              >
                                <b-form-input
                                  id="url_link"
                                  ref="url_link"
                                  v-model="formData.url_link[index]"
                                  type="url"
                                />
                                <span class="mt-1 font-small-2">
                                  Ex: https://www.google.com/
                                </span>
                              </b-form-group>
                            </b-col>
                            <b-col cols="2">
                              <b-button
                                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                v-b-tooltip.hover
                                variant="outline-danger"
                                class="btn-icon mt-0 mt-md-2"
                                title="Delete"
                                @click="removeItem(index)"
                              >
                                <feather-icon icon="XIcon" />
                              </b-button>
                            </b-col>
                          </b-row>
                        </b-col>
                        <!-- Sosmed Link -->
                        <b-col
                          md="12"
                          class="social-media-links mt-3"
                        >
                          <h5 class="font-medium-4">
                            Social Media Links
                          </h5>
                          <p class="font-small-3">
                            (Please use complete URL: https://instagram.com/youraccount)
                          </p>
                          <b-form-group>
                            <span class="font-small-3">Instagram URL</span>
                            <b-form-input
                              id="instagram_url"
                              ref="instagram_url"
                              v-model="formData.instagram"
                              type="text"
                            />
                          </b-form-group>
                          <b-form-group>
                            <span class="font-small-3">Facebook URL</span>
                            <b-form-input
                              id="facebook_url"
                              ref="facebook_url"
                              v-model="formData.facebook"
                              type="text"
                            />
                          </b-form-group>
                          <b-form-group>
                            <span class="font-small-3">Linkedin URL</span>
                            <b-form-input
                              id="linkedin_url"
                              ref="linkedin_url"
                              v-model="formData.linkedin"
                              type="text"
                            />
                          </b-form-group>
                          <b-form-group>
                            <span class="font-small-3">Youtube URL</span>
                            <b-form-input
                              id="youtube_url"
                              ref="youtube_url"
                              v-model="formData.youtube"
                              type="text"
                            />
                          </b-form-group>
                          <b-form-group>
                            <span class="font-small-3">TikTok URL</span>
                            <b-form-input
                              id="tiktok_url"
                              ref="tiktok_url"
                              v-model="formData.tiktok"
                              type="text"
                            />
                          </b-form-group>
                        </b-col>
                        <!-- SEO -->
                        <b-col cols="12">
                          <h4 class="mt-2">
                            SEO
                          </h4>
                        </b-col>
                        <!-- Page Title -->
                        <b-col
                          cols="12"
                        >
                          <b-form-group>
                            <label for="page_title">Page Title (max 50 char)</label>
                            <small class="text-danger font-weight-bold"> *</small>
                            <b-form-input
                              id="page_title"
                              ref="page_title"
                              v-model="formData.page_title"
                              type="text"
                              placeholder=""
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_page_title"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                            <b-link
                              href="#"
                              class="d-flex justify-content-end"
                              style="font-size: 12px"
                              @click="copyHeadingTitle"
                            >
                              Copy from Heading Title
                            </b-link>
                          </b-form-group>
                        </b-col>
                        <!-- Meta Description -->
                        <b-col
                          cols="12"
                        >
                          <b-form-group>
                            <label for="meta_description">Meta Description (max 160 char)</label>
                            <b-form-textarea
                              id="meta_description"
                              ref="meta_description"
                              v-model="formData.meta_description"
                              trim
                              placeholder=""
                              rows="1"
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_meta_description"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                            <b-link
                              href="#"
                              class="d-flex justify-content-end"
                              style="font-size: 12px"
                              @click="copyHeadingParagraph"
                            >
                              Copy from Heading Paragraph
                            </b-link>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-col>
                    <!-- Image Preview -->
                    <b-col
                      cols="12"
                      md="6"
                    >
                      <b-button
                        v-b-toggle.landing-page-sample-image
                        class="landing-page-sample-image-btn btn-icon d-none mb-2"
                        block
                        variant="outline-secondary"
                        style="height: fit-content;"
                      >
                        <span>Sample Image</span>
                      </b-button>
                      <b-collapse id="landing-page-sample-image">
                        <img
                          :src="require('@/assets/images/pages/landing_pages_preview.jpg')"
                          alt="Logo"
                          class="w-100"
                        >
                      </b-collapse>
                    </b-col>
                  </b-row>
                  <hr>
                </b-form>
              </div>
            </b-tab>
            <!-- Thank You Page -->
            <b-tab
              title="Thank You Page"
            >
              <b-row class="d-flex justify-content-start">
                <!-- Forms -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group>
                    <label for="title_typ">Heading Title (max 30 char)</label>
                    <small class="text-danger font-weight-bold"> *</small>
                    <b-form-input
                      id="title_typ"
                      ref="title_typ"
                      v-model="formData.title_typ"
                      :maxlength="30"
                      type="text"
                      placeholder=""
                    />
                    <b-form-invalid-feedback>
                      <span
                        ref="invalid_title_typ"
                        class="text-danger"
                      />
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <!-- Description -->
                  <b-form-group>
                            <label for="body_typ">Body Description</label>
                            <small class="text-danger font-weight-bold"> *</small>
                            <quill-editor
                              id="body_typ"
                              ref="body_typ"
                              v-model="formData.body_typ"
                              :options="editorOption"
                            />
                            <b-form-invalid-feedback>
                              <span
                                ref="invalid_body_typ"
                                class="text-danger"
                              />
                            </b-form-invalid-feedback>
                          </b-form-group>
                  <b-form-checkbox
                    v-model="formData.offer_download_pdf"
                    :checked="formData.offer_download_pdf"
                    name="check-button"
                    class="pt-1"
                    switch
                    inline
                  >
                    Offer download PDF
                  </b-form-checkbox>
                  <b-form-group
                    class="mt-1"
                  >
                    <label for="cta_label">Button Label</label>
                    <small class="text-danger font-weight-bold"> *</small>
                    <b-form-input
                      id="cta_label"
                      ref="cta_label_typ"
                      v-model="formData.cta_label_typ"
                      :disabled="!formData.offer_download_pdf"
                      type="text"
                      placeholder=""
                    />
                    <b-form-invalid-feedback>
                      <span
                        ref="invalid_cta_label_typ"
                        class="text-danger"
                      />
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    class="pt-0 mt-2"
                  >
                    <span class="font-small-3">File (PDF) Location <span class="text-danger">*</span></span>
                    <b-form-radio-group
                      v-model="formData.selected_typ"
                      :disabled="!formData.offer_download_pdf"
                      :options="typ_file_options"
                      class="demo-inline-spacing"
                      name="radio-inline"
                      @change="changeTypSelection()"
                    />
                  </b-form-group>
                  <b-form-group
                    v-if="formData.selected_typ === 'url'"
                    v-show="url_selected_typ"
                  >
                    <label for="cta_url">URL</label>
                    <small class="text-danger font-weight-bold"> *</small>
                    <b-form-input
                      id="cta_url"
                      ref="cta_url_typ"
                      v-model="formData.cta_url_typ"
                      :disabled="!formData.offer_download_pdf"
                      type="text"
                      placeholder=""
                    />
                    <b-form-invalid-feedback>
                      <span
                        ref="invalid_cta_url_typ"
                        class="text-danger"
                      />
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group
                    v-else-if="formData.selected_typ === 'file'"
                    v-show="file_selected_typ"
                  >
                    <label for="cta_file_typ">Drop PDF here (max 1 MB)</label>
                    <small class="text-danger font-weight-bold"> *</small>
                    <b-form-file
                      id="cta_file_typ"
                      ref="cta_file_typ"
                      v-model="formData.cta_file_typ"
                      :disabled="!formData.offer_download_pdf"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                    />
                    <b-form-invalid-feedback>
                      <span
                        ref="invalid_cta_file_typ"
                        class="text-danger"
                      />
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <!-- Image Preview -->
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-button
                    v-b-toggle.thankyou-page-sample-image
                    class="thankyou-page-sample-image-btn btn-icon d-none mb-2"
                    block
                    variant="outline-secondary"
                    style="height: fit-content;"
                  >
                    <span>Sample Image</span>
                  </b-button>
                  <b-collapse id="thankyou-page-sample-image">
                    <img
                      :src="require('@/assets/images/pages/thank_you_page.png')"
                      alt="Logo"
                      class="w-100"
                    >
                  </b-collapse>
                </b-col>
              </b-row>
            </b-tab>
            <!-- Additional Settings -->
            <b-tab
              title="Additional Settings"
            >
              <div>
                <span class="demo-inline-spacing mb-1">
                  <h5>Marketing Channel</h5>
                  <b-button
                    size="sm"
                    variant="gradient-primary"
                    class="btn-icon rounded-circle btn-form-repeater"
                    @click="repeateChannel"
                  >
                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </span>
                <b-row
                  v-for="(channel, index) in marketing_channels"
                  :id="channel.id"
                  :key="channel.id"
                  ref="rowChannel"
                >
                  <!-- Channel Name -->
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      label="Channel Name"
                      label-for="marketing_name"
                    >
                      <b-form-input
                        id="marketing_name"
                        ref="marketing_name"
                        v-model="formData.marketing_name[index]"
                        :disabled="formData.have_leads && formData.have_leads[index]"
                        type="text"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- URL -->
                  <b-col
                    cols="12"
                    md="2"
                  >
                    <b-form-group
                      label="URL"
                      label-for="marketing_url"
                    >
                      <b-form-input
                        id="marketing_url"
                        ref="marketing_url"
                        v-model="formData.marketing_url[index]"
                        :disabled="formData.have_leads && formData.have_leads[index]"
                        type="text"
                        @input="onChangeURL(formData.marketing_url[index], index)"
                      />
                    </b-form-group>
                  </b-col>
                  <!-- Channel URL -->
                  <b-col class="w-100">
                    <span class="font-small-2">Channel URL</span>
                    <b-form-group class="px-0">
                      <div class="d-flex">
                        <div class="d-flex flex-column w-100">
                          <b-input-group>
                            <b-form-input
                              ref="channel_url"
                              disabled
                              :value="channelURL(formData.marketing_url[index])"
                            />
                            <b-input-group-append>
                              <b-button
                                id="copy_marketing_url"
                                variant="outline-secondary"
                                style="width: fit-content;"
                                @click="copyUrl(formData.marketing_url[index])"
                              >
                                <feather-icon icon="CopyIcon" />
                              </b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </div>
                      </div>
                    </b-form-group>
                  </b-col>
                  <!-- Remove Button URL -->
                  <b-col
                    lg="1"
                    md="1"
                  >
                    <span
                      v-b-tooltip.hover.top
                      :title="formData.have_leads && formData.have_leads[index] ? 'Unable to delete due to existing active lead records coming from this Marketing Channel.' : ''"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        class="btn-icon mt-0 mt-md-2"
                        :disabled="formData.have_leads && formData.have_leads[index]"
                        @click="removeChannel(index)"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                    </span>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-overlay>
    <div class="d-flex pl-0 mt-1">
      <div class="footer-btn">
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          type="submit"
          variant="primary"
          class="submit-btn"
          :disabled="disableBtn"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          @click="submitForm"
        >
          <span class="align-middle">
            {{ this.$route.params.id !== undefined ? 'Update' : 'Publish' }}
          </span>
        </b-button>
        <span
          v-if="$route.params.id === undefined"
          class="text-btn"
        >You will be automatically added as Salesperson for this Landing page's Leads.
        </span>
      </div>

    </div>

  </b-card>
</template>

<script>
import {
  BCard, BForm, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend,
  BInputGroupAppend, BRow, BCol, BFormRadioGroup, BFormCheckbox,
  BButton, VBTooltip, BFormTextarea, BFormFile, BLink, BTabs, BTab,
  VBModal, BModal, BOverlay, BFormInvalidFeedback, VBToggle, BCollapse,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FormField from '@/views/menus/forms/form-display/FormField.vue'
import flatPickr from 'vue-flatpickr-component'
import colorPicker from '@caohenghu/vue-colorpicker'
import ColorOptions from '../../../components/custom/colorOptions'
import SeoOptions from '../../../components/custom/seoOptions'

export default {
  components: {
    Cleave,
    BTabs,
    BTab,
    BCard,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInvalidFeedback,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormTextarea,
    // BFormSelect,
    BFormFile,
    BModal,
    BLink,
    BOverlay,
    // BFormDatepicker,
    BFormRadioGroup,
    BFormCheckbox,
    vSelect,
    quillEditor,
    FormField,
    colorPicker,
    flatPickr,
    BCollapse,
  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple,
  },
  filters: {
    linkFormat(value) {
      return value !== undefined
        ? String(value).replace(/\s+/g, '-').toLowerCase()
        : ''
    },
  },
  mixins: [heightTransition],
  data() {
    return {
      showColorPicker: false,
      color: '#59c7f9',
      formOptions: [],
      formSelected: null,
      colorOptions: ColorOptions,
      seoOptions: SeoOptions,
      items: [],
      marketing_channels: [],
      analytics: [],
      nextLink: 2,
      nextChannel: 2,
      nextAnalytic: 2,
      selected: null,
      disableBtn: false,
      loadingState: true,
      showTooltipCopyUrl: false,
      msgTooltipCopyUrl: '',
      alertErrorTabHome: false,
      alertErrorTabTyp: false,
      file_selected_typ: false,
      url_selected_typ: true, // depends on initial selected_typ value
      formData: {
        description: '',
        body_description: '',
        submissions_count: 0,
        icon: null,
        hero_image: null,
        label: '',
        title: '',
        slug: '',
        label_submit_button: '',
        color_submit_button: '',
        submission_goal: null,
        sales_goal_deal: null,
        expired_start_date: null,
        expired_finish_date: null,
        aggreement: 'By submitting this form you agree with our privacy policy and terms & conditions',
        form_id: null,
        fill_in_sentence: '',
        text_link: [],
        color_link: [],
        url_link: [],
        have_leads: [],
        marketing_ids_channel: [],
        marketing_name: [],
        marketing_url: [],
        page_title: '',
        meta_description: '',
        marketing_seo: [],
        marketing_link_count: 0,
        analytics_total: 0,
        title_typ: 'Thank you.',
        body_typ: 'We have received your submission.',
        offer_download_pdf: false,
        cta_label_typ: '',
        cta_url_typ: '',
        cta_file_typ: '',
        selected_typ: 'url',
        instagram: '',
        facebook: '',
        linkedin: '',
        youtube: '',
        tiktok: '',
      },
      length_marketing_name: 0,
      length_marketing_url: 0,
      selfData: null,
      currencyFormat: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
      typ_file_options: [
        { text: 'I have it hosted at', value: 'url' },
        { text: 'I want to host it here', value: 'file' },
      ],
      /* eslint-disable */
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike', 'link'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ size: ['small', false, 'large', 'huge'] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ],
        },
      },
      /* eslint-enable */
    }
  },
  computed: {
    slug() {
      return this.formData.label !== undefined
        ? String(this.formData.label).replace(/\s+/g, '-').toLowerCase()
        : ''
    },
  },
  setup() {
    const toast = useToast()

    return {
      toast,
    }
  },
  mounted() {
    this.setDefaultStartDate()
    this.getSelfAndLandingPage()
  },
  methods: {
    setDefaultStartDate() {
      const date = new Date()
      const day = date.getDate()
      const month = date.getMonth() + 1
      const year = date.getFullYear()
      this.formData.expired_start_date = `${day}-${month}-${year}`
    },
    channelURL(url) {
      const marketingURL = url !== undefined
        ? String(url).replace(/\s+/g, '-').toLowerCase()
        : ''

      return this.selfData === null
        ? 'channel /'
        : `tracebit.net/${this.selfData.url_company_name}/${this.formData.slug}?channel=${marketingURL}`
    },
    copyUrl(label) {
      if (label) {
        const labelUrl = this.selfData === null
          ? 'channel/'
          : `tracebit.net/${this.selfData.url_company_name}/${this.formData.slug}?channel=`
        const url = labelUrl + this.$options.filters.linkFormat(label)
        navigator.clipboard.writeText(url)
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'URL Copy to clipboard',
          },
        })
      } else {
        this.toast({
          component: ToastificationContent,
          props: {
            title: 'Fail',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Please fill URL field',
          },
        })
      }
    },
    copyHeadingTitle() {
      this.formData.page_title = this.formData.title
    },
    copyHeadingParagraph() {
      this.formData.meta_description = this.formData.description
    },
    changeColor(color) {
      const btn = document.querySelector('.submit_btn_preview')
      if (typeof color !== 'string') {
        const {
          rgba: {
            r,
            g,
            b,
            a,
          },
        } = color
        this.color = `rgba(${r}, ${g}, ${b}, ${a})`
        btn.style.setProperty('--red', r)
        btn.style.setProperty('--green', g)
        btn.style.setProperty('--blue', b)
      } else {
        const regExp = /\(([^)]+)\)/
        const matches = regExp.exec(color)
        if (matches !== null && matches !== undefined) {
          const splits = matches[1].split(',')
          btn.style.setProperty('--red', splits[0])
          btn.style.setProperty('--green', splits[1])
          btn.style.setProperty('--blue', splits[2])
        }
      }

      const hoverShadow = document.querySelector('.submit_btn_preview')
      hoverShadow.addEventListener('mouseenter', () => {
        hoverShadow.style.boxShadow = `0 8px 25px -8px ${this.color}`
      })
      hoverShadow.addEventListener('mouseleave', () => {
        hoverShadow.style.boxShadow = ''
      })
    },
    getDetailLandingPage() {
      if (this.$route.params.id !== undefined){
        const payload = {
          id: this.$route.params.id,
          user_id: this.selfData !== null ? this.selfData.id : null,
        }
        store.dispatch('menu-landing-page/getLandingPage', payload)
          .then(response => {
            this.formData = response.data.data

            this.formData.text_link = JSON.parse(this.formData.text_link)
            this.formData.color_link = JSON.parse(this.formData.color_link)
            this.formData.url_link = JSON.parse(this.formData.url_link)
            this.formData.link_total = this.formData.text_link.length

            // Initialize submit button
            this.color = this.formData.color_submit_button
            this.changeColor(this.color)

            // initialize thank you tab
            if (this.formData.thank_you !== null && this.formData.thank_you !== undefined){
              this.formData.title_typ = this.formData.thank_you.title
              this.formData.body_typ = this.formData.thank_you.body
              this.formData.cta_label_typ = this.formData.thank_you.cta_label
              this.formData.selected_typ = this.formData.thank_you.cta_url !== null ? 'url' : 'file'
              if (this.formData.selected_typ === 'url') {
                this.file_selected_typ = false
                this.url_selected_typ = true
              } else if (this.formData.selected_typ === 'file') {
                this.url_selected_typ = false
                this.file_selected_typ = true
              }
              this.formData.cta_url_typ = this.formData.thank_you.cta_url ?? ''
              // toggle TYP show dowload file
              if (this.formData.thank_you.offer_download_file === 1){
                this.formData.offer_download_pdf = true
              } else {
                this.formData.offer_download_pdf = false
              }
            } else {
              this.formData.title_typ = ''
              this.formData.body_typ = ''
              this.formData.offer_download_pdf = false
              this.formData.cta_label_typ = ''
              this.formData.cta_url_typ = ''
              this.formData.cta_file_typ = ''
            }

            // Initialize marketing channel tab
            if (this.formData.marketing_link.length > 0){
              this.formData.marketing_link_count = this.formData.marketing_link.length
              this.formData.marketing_name = this.formData.marketing_link.map(val => {
                return val.name
              })
              this.formData.marketing_url = this.formData.marketing_link.map(val => {
                return val.url
              })
              this.formData.marketing_ids_channel = this.formData.marketing_link.map(val => {
                return val.id
              })
              this.formData.have_leads = this.formData.marketing_link.map(val => {
                return val.have_leads
              })
              this.length_marketing_name = this.formData.marketing_name.length
              this.length_marketing_url = this.formData.marketing_url.length
            } else {
              this.formData.marketing_name = []
              this.formData.marketing_url = []
              this.formData.marketing_ids_channel = []
              this.formData.have_leads = []
              this.length_marketing_name = 0
              this.length_marketing_url = 0
              this.formData.marketing_link_count = 0
            }

            // initialize analytics tab
            if (this.formData.analytic !== null && this.formData.analytic !== undefined){
              this.formData.marketing_seo = JSON.parse(this.formData.analytic.marketing_seo)

              if (Array.isArray(this.formData.marketing_seo)){
                this.formData.analytics_total = this.formData.marketing_seo.length
              }
              this.formData.page_title = this.formData.analytic.page_title
              this.formData.meta_description = this.formData.analytic.meta_description
            } else {
              this.formData.marketing_seo = []
              this.formData.analytics_total = 0
              this.formData.page_title = ''
              this.formData.meta_description = ''
            }

            // open all collapsed link
            if (this.formData.link_total !== undefined && this.formData.link_total !== null){
              let i = this.formData.link_total
              while (i > 0) {
                this.repeateAgain()
                i -= 1
              }
            }
            // open all collapsed channel
            if (this.formData.marketing_link_count !== undefined || this.formData.marketing_link_count !== null){
              let i = this.formData.marketing_link_count
              while (parseInt(i, 10) > 0) {
                this.marketing_channels.push({
                  id: this.nextChannel += this.nextChannel,
                })
                i -= 1
              }
            }
            // open all collapsed marketing analytics
            if (this.formData.analytics_total !== undefined || this.formData.analytics_total !== null){
              let i = this.formData.analytics_total
              while (parseInt(i, 10) > 0) {
                this.repeateAnalytic()
                i -= 1
              }
            }
            if (this.formData.same_company_users_id.includes(this.selfData.id)) {
              this.loadingState = false
            } else {
              this.$emit('refetch-empty-data', false)
            }
          })
          .catch(error => {
            if (error.response.status !== 200){
              this.$emit('refetch-empty-data', false)
            }
          })
      }
    },
    getSelfAndLandingPage() {
      store.dispatch('app-user/getSelf')
        .then(response => {
          this.selfData = response.data
          this.selfData.username = response.data.name.replace(/\s+/g, '-').toLowerCase()
          this.selfData.companyName = response.data.company.name.replace(/\s+/g, '-').toLowerCase()

          this.getDetailLandingPage()
          this.getAllForms()
        })
    },
    getAllForms() {
      store.dispatch('menu-form/getAllForms', {
        user_id: this.selfData.id,
      })
        .then(response => {
          this.formOptions = response.data.data
        })
    },
    changeTypSelection() {
      if (this.formData.selected_typ === 'file') {
        this.url_selected_typ = false
        this.file_selected_typ = true
      } else if (this.formData.selected_typ === 'url') {
        this.file_selected_typ = false
        this.url_selected_typ = true
      }
    },
    repeateAgain() {
      this.items.push({
        id: this.nextLink += this.nextLink,
      })
    },
    repeateChannel() {
      this.marketing_channels.push({
        id: this.nextChannel += this.nextChannel,
      })
      this.formData.marketing_ids_channel.push(0)
    },
    repeateAnalytic() {
      this.analytics.push({
        id: this.nextAnalytic += this.nextAnalytic,
      })
    },
    onChangeTitle(titleText) {
      this.formData.slug = titleText.replace(/\s+/g, '-').toLowerCase()
    },
    onChangeURL(url, index) {
      const marketingURL = url !== undefined
        ? String(url).replace(/\s+/g, '-').toLowerCase()
        : ''

      this.$refs.channel_url[index].value = this.selfData === null
        ? 'channel /'
        : `tracebit.net/${this.selfData.url_company_name}/${this.formData.slug}?channel=${marketingURL}`
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.formData.text_link.splice(index, 1)
      this.formData.color_link.splice(index, 1)
      this.formData.url_link.splice(index, 1)
    },
    removeChannel(index) {
      this.marketing_channels.splice(index, 1)
      this.formData.marketing_name.splice(index, 1)
      this.formData.marketing_url.splice(index, 1)
      this.formData.marketing_ids_channel.splice(index, 1)
    },
    removeAnalytic(index) {
      this.analytics.splice(index, 1)
      this.formData.marketing_seo = this.formData.marketing_seo.slice(0, this.analytics.length)
    },
    slugFormatter(value) {
      this.formData.slug = value.toLowerCase()
      return value.toLowerCase()
    },
    successToast(response) {
      return this.toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'CheckIcon',
          variant: 'success',
          text: response.data.message,
        },
      })
    },
    failToast(error) {
      return this.toast({
        component: ToastificationContent,
        props: {
          title: 'Error has occured',
          icon: 'XIcon',
          variant: 'danger',
          text: error.response.data.message,
        },
      })
    },
    customToast(title, message, icon, variant) {
      return this.toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
          text: message,
        },
      })
    },
    submitForm() {
      this.disableBtn = true
      const newFormData = new FormData()
      // assign to formData var
      if (this.formData.description !== null){
        newFormData.append('description', this.formData.description)
      }
      if (this.formData.expired_finish_date !== null){
        newFormData.append('expired_finish_date', this.formData.expired_finish_date)
      }
      if (this.formData.expired_start_date !== null){
        newFormData.append('expired_start_date', this.formData.expired_start_date)
      }
      if (this.formData.icon !== null){
        newFormData.append('icon', this.formData.icon)
      }
      if (this.formData.hero_image !== null){
        newFormData.append('hero_image', this.formData.hero_image)
      }
      if (this.formData.title !== null){
        newFormData.append('title', this.formData.title)
      }
      if (this.formData.label !== null){
        this.formData.slug = this.slug
        newFormData.append('label', this.formData.label)
      }
      if (this.formData.body_description !== null){
        newFormData.append('body_description', this.formData.body_description)
      }
      if (this.formData.slug !== null){
        newFormData.append('slug', this.formData.slug)
      }
      if (this.formData.aggreement !== null){
        newFormData.append('aggreement', this.formData.aggreement)
      }
      if (this.formData.aggreement !== null){
        this.formData.color_submit_button = this.color
        newFormData.append('color_submit_button', this.formData.color_submit_button)
      }
      if (this.formData.aggreement !== null){
        newFormData.append('label_submit_button', this.formData.label_submit_button)
      }
      if (this.formData.submission_goal !== null){
        newFormData.append('submission_goal', this.formData.submission_goal)
      }
      if (this.formData.sales_goal_deal !== null){
        this.formData.sales_goal_deal = parseInt(this.formData.sales_goal_deal.split(',').join(''), 10)
        newFormData.append('sales_goal_deal', this.formData.sales_goal_deal)
      }
      if (this.formData.form_id !== null){
        newFormData.append('form_id', this.formData.form_id)
      }
      if (this.formData.fill_in_sentence !== null){
        newFormData.append('fill_in_sentence', this.formData.fill_in_sentence)
      }
      if (this.formData.text_link !== null){
        newFormData.append('text_link', JSON.stringify(this.formData.text_link))
      }
      if (this.formData.color_link !== null){
        newFormData.append('color_link', JSON.stringify(this.formData.color_link))
      }
      if (this.formData.url_link !== null){
        newFormData.append('url_link', JSON.stringify(this.formData.url_link))
      }
      if (this.formData.marketing_name !== null){
        newFormData.append('marketing_name', JSON.stringify(this.formData.marketing_name))
      }
      if (this.formData.marketing_url !== null){
        newFormData.append('marketing_url', JSON.stringify(this.formData.marketing_url))
      }
      if (this.formData.page_title !== null){
        if (this.formData.page_title === undefined || this.formData.page_title === undefined) {
          newFormData.append('page_title', null)
        } else {
          newFormData.append('page_title', this.formData.page_title)
        }
      }
      if (this.formData.meta_description !== null){
        if (this.formData.meta_description === undefined || this.formData.meta_description === undefined) {
          newFormData.append('meta_description', null)
        } else {
          newFormData.append('meta_description', this.formData.meta_description)
        }
      }
      if (this.formData.marketing_seo !== null){
        newFormData.append('marketing_seo', JSON.stringify(this.formData.marketing_seo))
      }
      if (this.formData.marketing_ids_channel !== null){
        newFormData.append('marketing_ids_channel', JSON.stringify(this.formData.marketing_ids_channel))
      }
      if (this.formData.title_typ !== null){
        newFormData.append('title_typ', this.formData.title_typ)
      }
      if (this.formData.body_typ !== null){
        newFormData.append('body_typ', this.formData.body_typ)
      }
      if (this.formData.offer_download_pdf !== null){
        if (this.formData.offer_download_pdf === 'true' || this.formData.offer_download_pdf === true || this.formData.offer_download_pdf === 1){
          newFormData.append('offer_download_pdf', true)
        } else {
          newFormData.append('offer_download_pdf', false)
        }
      }
      if (this.formData.selected_typ !== null){
        newFormData.append('selected_typ', this.formData.selected_typ)
      }
      if (this.formData.cta_label_typ !== null){
        newFormData.append('cta_label_typ', this.formData.cta_label_typ)
      }
      if (this.formData.cta_url_typ !== null && this.formData.selected_typ === 'url'){
        newFormData.append('cta_url_typ', this.formData.cta_url_typ)
      }
      if (this.formData.cta_file_typ !== null && this.formData.selected_typ === 'file'){
        newFormData.append('cta_file_typ', this.formData.cta_file_typ)
      }
      if (this.formData.instagram !== null){
        newFormData.append('instagram', this.formData.instagram)
      }
      if (this.formData.facebook !== null){
        newFormData.append('facebook', this.formData.facebook)
      }
      if (this.formData.linkedin !== null){
        newFormData.append('linkedin', this.formData.linkedin)
      }
      if (this.formData.youtube !== null){
        newFormData.append('youtube', this.formData.youtube)
      }
      if (this.formData.tiktok !== null){
        newFormData.append('tiktok', this.formData.tiktok)
      }
      newFormData.append('link_total', this.items.length)
      newFormData.append('channel_total', this.marketing_channels.length)
      newFormData.append('user_id', this.selfData.id)

      if (this.$route.params.id === undefined){
        Object.entries(this.formData).forEach(value => {
          const key = value[0]
          const invalidKey = `invalid_${key}`
          if (this.$refs[key]) {
            if (this.$refs[key].$el !== undefined) {
              this.$refs[key].$el.classList.remove('is-invalid')
            }
            if (this.$refs[invalidKey] !== undefined) {
              this.$refs[invalidKey].innerText = ''
            }
          }
        })
        store.dispatch('menu-landing-page/addLandingPage', newFormData)
          .then(response => {
            this.successToast(response)
            this.disableBtn = false
            // Data for Salesperson
            const salesPersonItem = {
              salesperson_id: this.selfData.id,
              landing_page_uuid: response.data.landing_page_uuid,
              added_to_list: false,
            }
            // Add User to Salesperson
            store.dispatch('menu-lead/addSalespersonList', salesPersonItem)
              .then(responseSalesperson => {
                this.successToast(responseSalesperson)
                this.disableBtn = false
                this.$router.push({ name: 'landing-pages-list' })
              })
              .catch(error => {
                if (error.responseSalesperson !== undefined){
                  this.failToast(error)
                  this.disableBtn = false
                }
              })
          })
          .catch(error => {
            if (error.response.data.message !== undefined){
              this.alertErrorTabTyp = false
              this.alertErrorTabHome = false
              Object.entries(error.response.data.message).forEach(value => {
                const key = value[0]
                const val = value[1]
                const invalidKey = `invalid_${key}`
                if (this.$refs[key]) {
                  this.$refs[invalidKey].innerText = val[0].toString()
                  this.$refs[key].$el.classList.add('is-invalid')
                }
                if (key === 'title_typ' || key === 'body_typ' || key === 'cta_label_typ' || key === 'cta_url_typ' || key === 'cta_file_typ'){
                  if (this.alertErrorTabTyp === false){
                    this.customToast(
                      'Error Form',
                      'Error field in Thank You Page tab',
                      'XIcon',
                      'danger',
                    )
                    this.alertErrorTabTyp = true
                  }
                }
                if (key === 'description'
                  || key === 'body_description'
                  || key === 'icon'
                  || key === 'hero_image'
                  || key === 'label'
                  || key === 'title'
                  || key === 'slug'
                  || key === 'form_id'
                  || key === 'label_submit_button'
                  || key === 'color_submit_button'
                  || key === 'submission_goal'
                  || key === 'sales_goal_deal'
                  || key === 'expired_start_date'
                  || key === 'expired_finish_date'
                  || key === 'page_title'
                  || key === 'meta_description'
                  || key === 'instagram' || key === 'facebook' || key === 'linkedin' || key === 'youtube' || key === 'tiktok'
                  || key === 'aggreement') {
                  if (this.alertErrorTabHome === false) {
                    this.customToast(
                      'Error Form',
                      'Error fields on Landing Page',
                      'XIcon',
                      'danger',
                    )
                    this.alertErrorTabHome = true
                  }
                }
              })
              this.disableBtn = false
            }
          })
      } else {
        newFormData.append('_method', 'PUT')
        newFormData.append('id', this.$route.params.id)
        store.dispatch('menu-landing-page/updateLandingPage', newFormData)
          .then(response => {
            this.successToast(response)
            this.disableBtn = false
            this.$router.push({ name: 'landing-pages-list' })
          })
          .catch(error => {
            this.failToast(error)
            this.disableBtn = false
          })
      }
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  :root {
    --red: 89;
    --green: 199;
    --blue: 249;
    --threshold: 0.5;
  }
  .repeater-form {
    overflow: hidden;
    transition: .35s height;
  }
  .hu-color-picker {
    background-color: #fff !important;
    width: 218px !important;
    margin-top: 2rem;
    transition: all 0.5s;
    box-shadow: none;
  }
  .hu-color-picker .color-show canvas {
    width: 100%;
  }
  .color-picker-card {
    min-width: fit-content;
    background-color: #fff !important;
  }
  .color-picker-card .card-body{
    padding: 5px 10px 10px 10px;
  }
  .color-picker-close {
    font-size: 40px;
  }
  .color-picker-close:hover {
    opacity: .75;
  }
  .submit_btn_preview,
  .submit_btn_preview:hover {
    --r: calc(var(--red) * 0.2126);
    --g: calc(var(--green) * 0.7152);
    --b: calc(var(--blue) * 0.0722);
    --sum: calc(var(--r) + var(--g) + var(--b));
    --perceived-lightness: calc(var(--sum) / 255);
    color: hsl(0, 0%, calc((var(--perceived-lightness) - var(--threshold)) * -10000000%)) !important;
    border-radius: 50rem !important;
  }
  .submit_btn_preview:hover {
    transform: none !important;
    box-shadow: 0 8px 25px -8px #59c7f9;
  }
  @media (max-width: 780px) {
    .color-picker {
      max-height: 0 !important;
      align-items: start !important;
      justify-content: center;
      z-index: 999;
    }
  }
  #expired_start_date__dialog_, #expired_finish_date__dialog_ {
    transform: none !important;
    top: 100% !important;
    left: auto !important;
  }
  .social-media-links {
    width: 100%
  }
  @media (min-width: 768px) {
    #landing-page-sample-image img,
    #thankyou-page-sample-image img {
      object-fit: contain;
    }
    .home-tab-content {
      overflow-y: auto !important;
    }
    .home-tab-content, #landing-page-sample-image img {
      max-height: 600px !important;
    }
    #landing-page-sample-image, #thankyou-page-sample-image {
      display: unset !important;
    }
  }
  @media (max-width: 768px) {
    .landing-page-sample-image-btn,
    .thankyou-page-sample-image-btn {
      display: unset !important;
    }
  }
  .footer-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .preview-btn, .submit-btn {
    margin-right: 1.5rem;
  }
  @media (max-width: 575px) {
    .footer-btn {
      display: flex;
      flex-direction: column;
    }
    .preview-btn, .submit-btn {
      margin-top: 1rem;
      margin-right: 1.5rem;
      margin-left: 1.5rem;
    }
    .text-btn {
      text-align: center;
    }
  }
  .ql-snow a {
    color: #06c !important;
  }
  .vs__selected-options input {
    display: none;
  }
  .landing-page-content {
    width: 100%;
    flex-direction: row;
  }
  .landing-page-content .nav-tabs {
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: auto;
  }
</style>
